"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.paima = void 0;
exports.paima = {
    game_erc20_index: -1,
    waitingForIndex: () => __awaiter(void 0, void 0, void 0, function* () {
        window.parent.postMessage({ target: 'game-caster', action: 'waitingForERC20' }, '*');
    }),
    start: () => __awaiter(void 0, void 0, void 0, function* () {
        window.parent.postMessage({ target: 'game-caster', action: 'connectWallet' }, '*');
    }),
    sendTX: (card1to9) => __awaiter(void 0, void 0, void 0, function* () {
        window.parent.postMessage({
            target: 'game-caster',
            action: 'sendTX',
            payload: {
                action: 'click',
                input: 'card-' + card1to9,
                erc20_index: exports.paima.game_erc20_index,
            },
        }, '*');
    }),
    connectSetup: () => __awaiter(void 0, void 0, void 0, function* () {
        window.addEventListener('message', event => {
            console.log('inner event', event);
            if (event &&
                event.data &&
                typeof event.data === 'object' &&
                event.data.target === 'iframe-game' &&
                event.data.action === 'setup') {
                console.log(`GAME CASTER SETUP: ${event.data}`);
                exports.paima.game_erc20_index = event.data.payload.erc20_index;
            }
        }, false);
    }),
    connectEvents: (callback) => __awaiter(void 0, void 0, void 0, function* () {
        window.addEventListener('message', event => {
            // console.log('inner event', event);
            if (event &&
                event.data &&
                typeof event.data === 'object' &&
                event.data.target === 'iframe-game' &&
                event.data.action === 'event') {
                console.log(`GAME CASTER EVENT: ${event.data}`);
                callback(event.data.payload);
            }
        }, false);
    }),
    getCards: () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield fetch(`${process.env.BACKEND_URI}/game/${exports.paima.game_erc20_index}/keys?${Array(9)
            .fill(0)
            .map((_, i) => `keys=card-${i + 1}`)
            .join('&')}`);
        if (!result.ok)
            throw new Error('Cannot fetch');
        const data = yield result.json();
        console.log('DATA', data);
        return data.values.map(x => ({
            card: parseInt(x.key.split('-')[1]),
            upwards: x.value === 'upwards',
        }));
    }),
};
