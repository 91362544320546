"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const pixi_js_1 = require("pixi.js");
const paima_1 = require("./paima");
/* Global Game State */
let ready = false;
let elapsed = 0.0;
const app = new pixi_js_1.Application();
const drawTextOverlay = (block) => {
    console.log('Add text');
    const graphics = new pixi_js_1.Graphics()
        .rect(app.screen.width / 2 - 250, app.screen.height / 10 - 30, 500, 60)
        .fill(0xe74c3c);
    const text = new pixi_js_1.Text({
        text: 'Event at block height ' + block,
        style: {
            fontSize: 40,
            fill: '#FFF',
        },
        anchor: 0.5,
        x: app.screen.width / 2,
        y: app.screen.height / 10,
    });
    app.stage.addChild(graphics);
    app.stage.addChild(text);
    setTimeout(() => {
        console.log('Remove text');
        text.destroy();
        graphics.destroy();
    }, 3000);
};
function start() {
    return __awaiter(this, void 0, void 0, function* () {
        yield app.init({ width: 1024, height: 1024 });
        document.body.appendChild(app.canvas);
        pixi_js_1.Assets.addBundle('fonts', [
            { alias: 'oswald', src: '/assets/Oswald-VariableFont_wght.ttf', data: { family: 'Oswald' } },
        ]);
        yield Promise.all([
            pixi_js_1.Assets.loadBundle('fonts'),
            pixi_js_1.Assets.load('/img/c1.png'),
            pixi_js_1.Assets.load('/img/c2.png'),
            pixi_js_1.Assets.load('/img/c3.png'),
            pixi_js_1.Assets.load('/img/c4.png'),
            pixi_js_1.Assets.load('/img/c5.png'),
            pixi_js_1.Assets.load('/img/c6.png'),
            pixi_js_1.Assets.load('/img/c7.png'),
            pixi_js_1.Assets.load('/img/c8.png'),
            pixi_js_1.Assets.load('/img/c9.png'),
            pixi_js_1.Assets.load('/img/b1.png'),
        ]);
        const cards = [];
        const cardStats = [];
        const addCard = (x, y, upwards) => {
            console.log({ x, y, upwards });
            const index = x * 3 + y + 1;
            const card = upwards ? pixi_js_1.Sprite.from(`/img/c${index}.png`) : pixi_js_1.Sprite.from(`/img/b1.png`);
            cards[index - 1] = card;
            cardStats[index - 1] = upwards;
            card.anchor.set(0.5);
            card.x = ((x + 1) * app.screen.width) / 4;
            card.y = ((y + 1) * app.screen.height) / 4;
            card.scale = 0.2;
            card.eventMode = 'static';
            card.cursor = 'pointer';
            card.on('pointerdown', () => __awaiter(this, void 0, void 0, function* () {
                yield paima_1.paima.sendTX(index);
                console.log('Event processed by paima-engine');
            }));
            app.stage.addChild(card);
            return card;
        };
        const initialState = yield paima_1.paima.getCards();
        console.log({ initialState });
        for (let x = 0; x < 3; x++) {
            for (let y = 0; y < 3; y++) {
                const index = x * 3 + y + 1;
                const card = initialState.find(i => i.card === index);
                console.log(card, index);
                addCard(x, y, card ? card.upwards : true);
            }
        }
        paima_1.paima.start();
        paima_1.paima.connectEvents((block) => __awaiter(this, void 0, void 0, function* () {
            drawTextOverlay(block);
            const initialState = yield paima_1.paima.getCards();
            for (let x = 0; x < 3; x++) {
                for (let y = 0; y < 3; y++) {
                    const index = x * 3 + y + 1;
                    const card = initialState.find(i => i.card === index);
                    if ((card === null || card === void 0 ? void 0 : card.upwards) !== cardStats[index - 1]) {
                        const scale = cards[index - 1].scale;
                        cards[index - 1].destroy();
                        const newCard = addCard(x, y, card ? card.upwards : true);
                        newCard.scale = scale;
                    }
                }
            }
        }));
        let positive = true;
        app.ticker.add(ticker => {
            elapsed += ticker.deltaTime;
            try {
                const target = ((elapsed / 100) | 0) % 9;
                if (cards[target].scale._x <= 0.2) {
                    positive = true;
                }
                if (cards[target].scale._x >= 0.27) {
                    positive = false;
                }
                if (positive)
                    cards[target].scale = cards[target].scale._x + 0.001;
                else
                    cards[target].scale = cards[target].scale._x - 0.001;
            }
            catch (e) {
                console.log('Tick', e);
            }
        });
        ready = true;
    });
}
function waitUntilIdLoaded() {
    return new Promise(resolve => {
        const interval = setInterval(() => {
            if (paima_1.paima.game_erc20_index >= 0) {
                clearInterval(interval);
                resolve(undefined);
            }
            else {
                paima_1.paima.waitingForIndex();
            }
        }, 250);
    });
}
paima_1.paima.connectSetup();
(() => __awaiter(void 0, void 0, void 0, function* () {
    yield waitUntilIdLoaded();
    yield start();
}))();
